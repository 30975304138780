import Form from '../../../components/app/forms/Form';
import Vue from 'vue'

export default class TransactionFormClass extends Form {
  
  constructor(data) {

    super()
    
    this.defaultParams = data

    this.reset();
  }

  setSession(session) {
    this.origin = 'session'
    this.type = 'in'
    this.session = session
    this.date = session.start_at_date
    this.description = this.makeDescription(session)
    this.customer = session?.customer

    if (this.customer?.value_per_session > 0) {
      this.value = this.customer.value_per_session
    }
  }

  makeDescription(session) {
    let description = `Referente a Sessão do dia ${Vue.$format.dateBr(session.start_at)} as ${Vue.$format.time(session.start_at_time)}`

    if (this.isBooking(session)) {
      description = session.booking.name + ' - ' + description
    }

    return description
  }


  isBooking(session) {
    return session.origin == 'booking'
  }
}
