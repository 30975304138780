<template>
  <v-dialog
    persistent
    scrollable
    v-model="dialog"
    width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="text">
        {{ title }}
        <app-delete-btn
          v-if="!isInsert"
          @click="handleDelete()"
          x-small
          class="ml-auto"
          type="icon"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-4">
          <v-col>
            <app-text-field v-model="form.description" label="Descrição" />
          </v-col>
        </v-row>
        <v-row v-if="form.customer && !isInsert">
          <v-col>
            <h3>{{ form.customer.name }}</h3>
          </v-col>
        </v-row>
        <v-row v-if="isInsert">
          <v-col>
            <CustomerSearch
              v-model="form.customer"
              :label="`${customerAlias} (Opcional)`"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <app-date-picker v-model="form.date" label="Data" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <app-number-field v-model="form.value" label="Valor" suffix="R$" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 text-right">
            <v-switch
              v-model="form.status"
              true-value="paid"
              false-value="pending"
              :label="paidLabel"
              hide-details
              @change="handlePaidStatusChange()"
            />
          </v-col>
          <v-col class="text-right">
            <v-btn
              small
              outlined
              color="primary"
              v-if="showButtonSubmitBilling"
              @click="handleSubmitBilling()"
              ><v-icon class="mr-2" small>mdi-send</v-icon>Enviar
              Cobrança</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="pt-0">
          <v-col>
            <v-textarea
              v-model="form.details"
              label="Observações"
              hide-details
              auto-grow
              outlined
              flat
            />
          </v-col>
        </v-row>
        <v-row v-if="form.session_id">
          <v-col>
            <v-alert class="mb-0" outlined type="info" text>
              <template v-for="(session, index) of form.session_group.sessions">
                <div :key="index">
                  <small
                    >{{ $format.dateBr(session.start_at_date) }} -
                    {{ $format.time(session.start_at_time) }}
                  </small>
                  <SessionStatusChip :value="session.status" />
                </div>
              </template>
            </v-alert>
          </v-col>
        </v-row>
        <template v-if="canCreateReceipt">
          <v-row>
            <v-col>
              <h3>Recibos</h3>
              <CustomerDocumentList
                :customerDocuments="form.customer_documents"
              ></CustomerDocumentList>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-btn
                small
                outlined
                color="primary"
                @click="handleStoreDocument()"
                >Gerar Recibo</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <app-cancel-btn
          v-if="form.status != 'canceled' && !isInsert"
          @click="handleCancel()"
          outlined
          rounded
          color="error"
          class="mr-auto"
        />

        <v-btn @click="dialog = false" rounded outlined>Voltar</v-btn>
        <v-btn @click="handleSave()" class="mr-2" rounded color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>

      <StoreDocumentForm
        ref="StoreDocumentForm"
        documentType="receipt"
        :customer="customer"
        :professional="professional"
        :transaction="form"
        :session_group="session_group"
        @store="showTransaction(form.id)"
      />
      <BillingNotificationForm ref="BillingNotificationForm" />
    </v-card>
  </v-dialog>
</template>

<script>
import CustomerSearch from "@/components/customers/ui/CustomerSearch";
import CustomerDocumentList from "@/components/customers/lists/CustomerDocumentList";
import StoreDocumentForm from "@/components/customers/sections/StoreDocumentForm.vue";
import SessionStatusChip from "@/components/session/sections/SessionStatusChip.vue";
import BillingNotificationForm from "@/components/transactions/form/BillingNotificationForm";
import TransactionForm from "./TransactionForm";
import { format } from "date-fns";
import { customerAlias } from "@/utils/customerAlias";

export default {
  components: {
    CustomerDocumentList,
    StoreDocumentForm,
    CustomerSearch,
    SessionStatusChip,
    BillingNotificationForm,
  },

  data() {
    return {
      customerAlias: customerAlias(),
      dialog: false,

      sessionStatuses: {
        shown: "Compareceu",
        pending: "Aguardando",
      },

      form: new TransactionForm({
        id: null,
        customer: {},
        session_group_id: null,
        session_group: {
          customer: {},
          sessions: [],
        },
        session: null,
        customer: null,
        customer_documents: [],
        type: null,
        origin: null,
        description: null,
        details: null,
        date: null,
        paid_at: null,
        paid_value: null,
        value: null,
        status: "pending",
      }),
    };
  },

  created() {
    this.reset();
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },

    showButtonSubmitBilling() {
      return (
        !this.isInsert &&
        this.form.status == "pending" &&
        this.hasCustomer &&
        this.form.type == "in"
      );
    },

    isCustom() {
      return this.form.origin == "custom";
    },

    isPaid() {
      return this.form.status == "paid";
    },

    title() {
      return this.form.type == "in" ? "Entrada" : "Saida";
    },

    paidLabel() {
      return this.form.type == "in" ? "Recebido" : "Pago";
    },

    customer() {
      return this.hasCustomer ? this.form.customer : "";
    },

    professional() {
      return this.isCustom ? "" : this.form.session_group.professional;
    },

    session_group() {
      return this.isCustom ? "" : this.form.session_group;
    },
    hasCustomer() {
      return this.form.customer?.id;
    },

    canCreateReceipt() {
      return this.hasCustomer && !this.isInsert;
    },
  },

  methods: {
    async open(id = null) {
      this.reset();
      if (id) {
        await this.showTransaction(id);
      }
      this.dialog = true;
    },

    new(type, session = null) {
      this.reset();

      if (!session) {
        this.form.origin = "custom";
        this.form.type = type;
      } else {
        this.form.setSession(session);
      }
      this.dialog = true;
    },

    reset() {
      this.form.reset();
    },

    async showTransaction(id) {
      await this.$http
        .show("transactions/transactions", id)
        .then((response) => {
          this.form.setParams(response.transaction);
          this.$loading.finish();
        });
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();

      this.$http
        .store("transactions/transactions", this.form.getParams())
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleCancel() {
      this.$loading.start();

      this.$http
        .destroy("transactions/transactions", this.form.id)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$loading.start();

      this.$http
        .update(
          "transactions/transactions",
          this.form.id,
          this.form.getParams()
        )
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
      this.$loading.finish();
    },

    handleStoreDocument() {
      // todo @celso: bug here, quando o usuario marca como pago, e gera um documento, da o refresh e volta o status antigo

      this.$refs.StoreDocumentForm.open();
    },

    handleDelete() {
      this.$loading.start();
      this.$http
        .destroy("transactions/delete", this.form.id)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSubmitBilling() {
      this.$refs.BillingNotificationForm.open(this.form.getParams());
    },
    handlePaidStatusChange() {
      if (!this.isInsert) {
        this.$loading.start();
        this.$http
          .update("transactions/receive", this.form.id, this.form.getParams())
          .then((response) => {
            this.$loading.finish();
            this.$emit("store");
            this.showTransaction(this.form.id);
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },
  },
};
</script>

<style>
</style>